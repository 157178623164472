import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Space, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const { Title } = Typography;

const HomePage: React.FC = () => {
	const navigate = useNavigate();
	return (
		<Space align="center" direction="vertical" style={{ justifyContent: 'center', flex: '1 0 auto' }}>
			<Space align="center" direction="vertical">
				<Title level={2}>+Pluswerk Planning Poker</Title>
				<Button onClick={() => navigate('/new-game')} type="primary" shape="round" icon={<CopyOutlined/>} size="large">Start new Poker</Button>
			</Space>
		</Space>
	);
}

export default HomePage;
