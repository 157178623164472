import React, { Fragment, useEffect, useState } from "react";
import { Typography, Button } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";

import { channelState, playerState, showCardsIntervalState, showCardsState, socketIoState } from "../States/GlobalStates";
import Player from "./Player";
import Confetti from "./Confetti";
import { VotingSystem } from "../Helper/Enum";

const {Title} = Typography;

const PokerTable: React.FC = () => {
	let interval: ReturnType<typeof setInterval>,
		timeOut: ReturnType<typeof setTimeout>;

	const playerInChannel = useRecoilValue(playerState);
	const channel = useRecoilValue(channelState);
	const socketState = useRecoilValue(socketIoState);
	const [showCards, setShowCards] = useRecoilState(showCardsState);
	const [revealCardsButtonText, setRevealCardsButtonText] = useState('Reveal cards');
	const [showConfetti, setShowConfetti] = useState(false);
	const [showCardsInterval, setShowCardsInterval] = useRecoilState(showCardsIntervalState);
	const [showAverage, setShowAverage] = useState(true);

	// hide average if specific votingSystem is set
	useEffect(() => {
		if (channel?.votingSystem === VotingSystem.three) {
			setShowAverage(false);
		}
	}, [channel]);

	useEffect(() => {
		socketState?.on('restartGame', () => {
			setShowCardsInterval(false);
			setShowCards(false);
			setShowConfetti(false);
		});
		socketState?.on('revealCards', () => {
			revealCards();
		});
		socketState?.on('showConfetti', (payload) => {
			if (payload && payload.hasOwnProperty('confetti') && payload.confetti !== showConfetti) {
				setTimeout(() => setShowConfetti(payload.confetti), 4000);
			}
		});

		return () => {
			clearTimeout(timeOut);
			clearInterval(interval);
			setRevealCardsButtonText('Reveal cards');
			setShowCardsInterval(false);
			setShowCards(false);
		}
		// eslint-disable-next-line
	}, []);

	const votingComplete = (): boolean => {
		const voting = playerInChannel.filter(player => player.vote === '' && !player.spectator);
		const votablePlayer = playerInChannel.filter(player => !player.spectator);
		return votablePlayer.length > 0 && voting.length < 1;
	};

	const emitRevealCards = () => {
		socketState?.emit('revealCards');
		revealCards();
	};

	const revealCards = () => {
		let intervalNr = 3;
		interval = setInterval(() => {
			setRevealCardsButtonText(`Show cards in ${intervalNr--}`);
		}, 1000);
		timeOut = setTimeout(() => {
			setShowCards(true);
			setShowCardsInterval(false);
			setShowConfetti(false);
			setRevealCardsButtonText('Reveal cards');
			clearTimeout(timeOut);
			clearInterval(interval);
			socketState?.emit('setShowCards', true);
		}, 4000);
		setShowCardsInterval(true);
	};

	const startNewGame = () => {
		socketState?.emit('restart');
	};

	const getAverage = () => {
		let count = 0;
		let total = 0;
		playerInChannel.forEach((player) => {
			if (player.vote) {
				let vote = player.vote;
				if (vote === '½') vote = '0.5';
				if (isNaN(parseFloat(vote))) return;
				total += !isNaN(parseFloat(vote)) ? parseFloat(vote) : 0;
				count++;
			}
		});
		if (total === 0 && count === 0) return 0;
		return (total / count).toFixed(1).replace(/\.0+$/, '')
	}

	return (
		<Fragment>
			{playerInChannel.length > 0 && (
				<div className="poker-table">
					<div className="poker-table__area1">
						{playerInChannel.filter((player, index) => [1, 2, 4, 14].includes(index)).map((player, index) => (
							<Player key={player.id} editable={socketState?.id === player.id} player={player}/>
						))}
					</div>
					<div className="poker-table__area2">
						{playerInChannel.filter((player, index) => [7, 9, 11, 13].includes(index)).map((player, index) => (
							<Player key={player.id} editable={socketState?.id === player.id} player={player}/>
						))}
					</div>
					<div className="poker-table__area3">
						{playerInChannel.filter((player, index) => [0, 3, 5, 15].includes(index)).map((player, index) => (
							<Player key={player.id} editable={socketState?.id === player.id} player={player}/>
						))}
					</div>
					<div className="poker-table__area4">
						{playerInChannel.filter((player, index) => [6, 8, 10, 12].includes(index)).map((player, index) => (
							<Player key={player.id} editable={socketState?.id === player.id} player={player}/>
						))}
					</div>
					<div className="poker-table__table">
						{votingComplete() || (showCards && showAverage) ? (
							<Fragment>
								{!showCards ? (
									<Button type="primary" disabled={showCardsInterval} shape="round" size="large" onClick={emitRevealCards}>{revealCardsButtonText}</Button>
								) : (
									<Fragment>
										{showConfetti && (
											<Confetti/>
										)}
										{showAverage && (
											<h1>Average: {getAverage()}</h1>
										)}
										<Button type="primary" shape="round" size="large" onClick={startNewGame} danger={true}>Start new voting</Button>
									</Fragment>
								)}
							</Fragment>
						) : (
							<Title level={3} style={{marginBottom: 0}}>Pick your cards!</Title>
						)}
					</div>
					<span className="poker-table__space1"/>
					<span className="poker-table__space2"/>
					<span className="poker-table__space3"/>
					<span className="poker-table__space4"/>
				</div>
			)}
		</Fragment>
	);
};

export default PokerTable;
